import React from 'react'
// Customizable Area Start
import { Link } from 'react-router-dom';
import { Col, Row, Rate } from "antd";
import { mainsub, ortho,location,banner7,banner9,banner10 } from "./assets";
import { Carousel } from "react-responsive-carousel";
import LandingpageController from "../src/LandingpageController.web";
import NavbarCommonComponent from '../../../components/src/NavbarCommonComponent.web';
import DoctorsCommonComponent from '../../../components/src/DoctorsCommonComponent.web';
import ProceduresCommonComponent from '../../../components/src/ProceduresCommonComponent.web';
import FooterCommonComponent from '../../../components/src/FooterCommonComponent.web';
import FooterDocotr from "../../../components/src/FooterDoctor.web";
import MerilLoader from '../../../components/src/MerilLoader.web';
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
let config = require('../../../framework/src/config.js').baseURL;

// Customizable Area End

class LandingPage extends LandingpageController {
    // Customizable Area Start
    renderNavigationTitle = () => {
        return (
                <div className="navigationContainer">
                    <Link to="/" >Doctor Appointments</Link>
                    <Link to="/" >Surgical Procedures</Link>
                    <Link to="/" >Therapies</Link>
                    <Link to="/" >Lab Tests</Link>
                </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderNavigationHomeTitle = () => {

        return (
                <div className="navigationContainer">
                    <Link to="/patienthome" className={window.location.pathname === "/patienthome" ? "activeColor" : ""}>Home</Link>
                    <Link to="/patient-appointment-landingpage">Appointments</Link>
                    <Link to="/Services">Services</Link>
                    <Link to="/patientmessage">Messages</Link>
                </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderCarousell = () => {
        return (
            <div>
                <Carousel
                    className='renderMainCarousel'
                    showStatus={false}
                    showThumbs={false}
                    showArrows={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    stopOnHover={false}
                    
                >
                     <div>
                        <img className='carouselImage' src={banner10} alt="CarouselImage"/>
                    </div>
                    <div>
                        <img className='carouselImage' src={banner7} alt="CarouselImage" />
                    </div>
                    <div>
                        <img className='carouselImage' src={banner9} alt="CarouselImage" />
                    </div>
                </Carousel>
            </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderOurServices = () => {
        let ourServicesDataShow = this.state.ourServicesDataSliced ? this.state.ourServicesData : this.state.ourServicesData.slice(0, 6)
        return (
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart topmargin'>
                        <h2 className='ourServicesTitle'>Our Services</h2>
                        <p data-test-id="ourServicesId" className='ourServicesSubTitle' onClick={this.handleServicesData}>
                            {
                                !this.state.ourServicesDataSliced ? "See All" : "View less"
                            }
                        </p>
                    </div>
                    <Row className='cardRow' gutter={[20, 20]}>
                        {ourServicesDataShow.map((item) => {
                            return (
                                <Col data-test-id="ourServicesID" key={item.id} xl={4} lg={6} md={8} sm={12} xs={24} onClick={()=>this.handleOurServices(item)} >
                                    <div className='servicesCard'>
                                        <div className='servicesCardImgBox'>
                                            <img src={config + item.attributes.service_image} className='servicesCarddImg' />
                                        </div>
                                        <p className='servicesCardText'>
                                            {item.attributes.title}
                                        </p>
                                        <p className='servicesSubText servicesSubTextHide' dangerouslySetInnerHTML={{
                                            __html: item.attributes.description.slice(0,60),
                                        }} />
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderTopHospitals = () => {
        let topHospitalsDataShow = this.state.topHospitalsDataSliced ? this.state.topHospitalsData.slice(0,6) : this.state.topHospitalsData.slice(0, 3)
        return (
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Top Hospitals</h2>
                        <p data-test-id="topHospitalsId" className='ourServicesSubTitle' onClick={this.handleTopHospitalsData}>
                            {
                                this.state.topHospitalsDataSliced ? "View less" : "See All"
                            }
                        </p>
                    </div>
                    <Row className='hospitalsRow' gutter={[20, 20]}>
                        {topHospitalsDataShow.map((item) => {
                            return (
                                <Col xl={8} lg={8} md={12} sm={12} xs={24} key={item.id} >
                                    <div className='topHospitalsCard'>
                                        <div className='hospiotalImgBox'>
                                            <img src={config + item.attributes.hospital_image[0]?.url} className='hospitalImg' key={item.id} alt="hospital_image" />
                                        </div>
                                        <div className='hospitalTextBox'>
                                            <div className='hospitalNameBox'>
                                                <p className='hospitalName'>{item.attributes.name}</p>
                                                <p className='hospitalDistance'><img style={{width:"24px",height:"24px"}} src={location} alt="loc" /> {item.attributes.distance} kms</p>
                                            </div>
                                            <div className='rateBox hospitalRate'>
                                                <div>
                                                    <Rate allowHalf disabled value={item.attributes.rating} />
                                                </div>
                                                <p className='reviewText'>{item.attributes?.total_reviews} &nbsp; {item.attributes?.total_reviews === 1 ? "review":"reviews" }</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderShortVideos = () => {
        let shortVideosDataShow = this.state.shortVideosDataSliced ? this.state.shortVideoListData : this.state.shortVideoListData.slice(0, 6)
        return (
          
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Short Videos</h2>
                        <p data-test-id="shortVideosId" className='ourServicesSubTitle' onClick={this.handleShortVideossData}>
                            {
                                this.state.shortVideosDataSliced ? "View less" : "See All"
                            }
                        </p>
                    </div>
                    <Row className='hospitalsRow' gutter={[20, 20]}>
                        {shortVideosDataShow.map((item: any) => {
                            return (
                                <Col xl={4} lg={6} md={8} sm={12} xs={24}>
                                    <div className='shortVideosCard'>
                                        <div className='shortVideosCardImgBox'>
                                            <video className='shortVideosCardImg' controls>
                                                <source src={config + item.attributes.stories[0]?.url} type="video/mp4" />
                                            </video>
                                        </div>
                                        <p className='shortVideosCardTextBox'>
                                            {item.attributes.name}
                                        </p>
                                    </div>
                                </Col>
                            )
                        })}

                    </Row>
                </div>
        )
    }
    // health stories
    renderHealthStories = () => {
        let HealthStoriesData = [
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Diabetes" },
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Diabetes" },
            { image: banner10, textName: "Cancer Care" },
            { image: banner10, textName: "Cancer Care" },
        ];
    
        return (
            <>
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Health Stories</h2>
                    </div>
                </div>
                <div className="healthStoriesContainer">
                    <div className="healthStoriesWrapper">
                        <div className="bigBackgroundBox"></div>
                        <Row gutter={[16, 24]} justify="center">
                            {HealthStoriesData.map((item, index) => (
                                <Col
                                    key={index}
                                    xl={4}
                                    lg={6}
                                    md={8}
                                    sm={12}
                                    xs={24}
                                    style={{ textAlign: "center" }}
                                >
                                    <div className="healthStoriesItem">
                                        <img
                                            src={item?.image}
                                            alt={item?.textName}
                                            className="healthStoriesImage"
                                        />
                                        <p className="healthStoriesText">{item?.textName}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            </>
        );
    };
    
    
    
    // 
    // Customizable Area End

    // Customizable Area Start
    renderCarouselSecondPart = () => {
        return (
                <Carousel
                    interval={3000}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    stopOnHover={false}
                    className='secondCarousel'
                >
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                    <div>
                        <img className='carouselImage secondCarouselImg' src={mainsub} />
                    </div>
                </Carousel>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderImageOrtho = () => {
        return (
            <div>
                <img className='orthoImg' src={ortho} alt="Ortho" />
            </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderPostCovidCare = () => {
        return (
                <div className='ourServicesContainer'>
                    <div className='ourServicesHeaderPart'>
                        <h2 className='ourServicesTitle'>Post Covid Care Package</h2>
                    </div>
                    {
                        this.state.postCovidCareData.map((item: any) => {
                            return (
                                <Carousel
                                    interval={3000}
                                    showThumbs={false}
                                    showArrows={false}
                                    showStatus={false}
                                    infiniteLoop={true}
                                    autoPlay={true}
                                    stopOnHover={false}
                                >
                                    {item.attributes?.images.map((subitem: any) => {
                                        return (
                                            <div className='advertisementImgBox'>
                                                <img className='advertisementImg' src={config + subitem?.url} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            )
                        })
                    }
                </div> 
               )
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        const isLogin = localStorage.getItem("isLogin");
        const isPatientGoogleLogin = localStorage.getItem("isPatientGoogleLogin");
        return (
            <div>
                <NavbarCommonComponent handleProfile={this.showDetailsHandler} />
               <PatientProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
                { isLogin || isPatientGoogleLogin ? this.renderNavigationHomeTitle() : this.renderNavigationTitle() }
                {this.renderCarousell()}
                <div className='itemContainerWiidth'>
                    {this.renderOurServices()}
                    <ProceduresCommonComponent data-test-id="trendingId" handleProceduresDataList={this.handleTherapiesData} dataList={this.state.trendingTherapiesDataSliced ? this.state.trendingTherapiesData.slice(0, 4) : this.state.trendingTherapiesData} heading={"Trending Therapies"} handleTrendingTherapies={this.handletrendingTherapies} />
                    <DoctorsCommonComponent data-test-id="topDoctorsId" handleDoctorsDataList={this.handleDoctorsNearbyData} dataList={this.state.topDoctorsDataSliced ? this.state.topDoctorsData.slice(0, 3) : this.state.topDoctorsData} heading={"Top Doctors Nearby"} />
                    {this.renderTopHospitals()}
                    {this.renderShortVideos()}
                    {this.renderHealthStories()}
                    <ProceduresCommonComponent data-test-id="topCardiovascularId" handleProceduresDataList={this.handleTopCardiovascularData} dataList={this.state.topCardiovascularDataSliced ? this.state.topCardiovascularData.slice(0, 4) : this.state.topCardiovascularData} heading={"Top Cardiovascular Procedures"} handleTrendingTherapies={this.handletrendingTherapies} />
                    {this.renderCarouselSecondPart()}
                    <DoctorsCommonComponent data-test-id="topCardiologistId" handleDoctorsDataList={this.handleTopCardiologistData} dataList={this.state.topCardiologistDataSliced ? this.state.topCardiologistDoctorsData.slice(0, 3) : this.state.topCardiologistDoctorsData.slice(0,6)} heading={"Top Cardiologist"} />
                    {this.renderPostCovidCare()}
                    <ProceduresCommonComponent data-test-id="topOrthopaedicId" handleProceduresDataList={this.handleTopOrthopaedicData} dataList={this.state.topOrthopaedicDataSliced ? this.state.topOrthopaedicData.slice(0, 4) : this.state.topOrthopaedicData.slice(0,5)} heading={"Top Orthopaedic Procedures"} handleTrendingTherapies={this.handletrendingTherapies} />
                    <DoctorsCommonComponent data-test-id="topOrthopaedicsDocId" handleDoctorsDataList={this.handleTopOrthopaedicsData} dataList={this.state.topOrthopaedicDoctorsDataSliced ? this.state.topOrthopaedicDoctorsData.slice(0, 3) : this.state.topOrthopaedicDoctorsData} heading={"Top Orthopaedic Doctors"} />
                    {this.renderImageOrtho()}
                    <DoctorsCommonComponent data-test-id="labTestID" handleDoctorsDataList={this.handleLabTestData} dataList={this.state.labTestDataSliced ? this.state.labTestData.slice(0, 3) : this.state.labTestData} heading={"Lab Tests"} />
                    <DoctorsCommonComponent data-test-id="radiologyTestID" handleDoctorsDataList={this.handleRadiologyData} dataList={this.state.radioLogyTestDataSliced ? this.state.radioLogyTestData.slice(0, 3) : this.state.radioLogyTestData.slice(0,6)} heading={"Radiology Tests"} />
                </div>
                {
                    isLogin ? (<FooterDocotr/>):    <FooterCommonComponent />
                }
             
            </div>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default MerilLoader(LandingPage);
export { LandingPage };
// Customizable Area End
