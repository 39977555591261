
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouterProps } from 'react-router';
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import React from "react";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


// Customizable Area Start
export type Props = RouterProps &
    withLoaderProps & {

        id: string;
        history: any;
        location: any;

    }
// Customizable Area End


export interface S {
    // Customizable Area Start
    isLoginUser: boolean,
    location: any,
    latitude: any,
    longitude: any
    isLocationInputTouched: boolean,
    cityname: string,
    cityAreas: any,
    doctorsListByArea: any,
    selectedArea: any
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LocationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start 
    getAppointmentsListApiCallId: any;
    getDoctorsListApiCalledId: any;
    getAreaByCityApiCalledId: any;
    getCurrentLocationCityName: any;
    myRef: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.myRef = React.createRef();
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoginUser: false,
            location: null,
            longitude: null,
            latitude: null,
            isLocationInputTouched: false,
            cityname: "",
            cityAreas: [],
            doctorsListByArea: [],
            selectedArea: ""
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    receive = async (from: string, message: Message) => {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson) {
                if (apiRequestCallId === this.getAreaByCityApiCalledId) {
                    let cityAreaStringToArray = responseJson.areas[0].split(", ");
                    this.setState({ cityAreas: cityAreaStringToArray });
                } else if (apiRequestCallId === this.getCurrentLocationCityName) {
                    this.setState({ cityname: responseJson.data.village });
                    localStorage.setItem("cityname", JSON.stringify(responseJson.data.village));
                    setStorageData("showCityname", JSON.stringify(responseJson.data.village))
                }
            }
            
        }
        runEngine.debugLog("Message Recived", message);




        // Customizable Area End
    };

    // Customizable Area Start


    /* istanbul ignore next */
    coords = () => {
        return new Promise((resolve, reject) =>
            navigator.permissions ?
                navigator.permissions.query({
                    name: 'geolocation'
                }).then(permission => {
                    permission.state === "prompt" || "granted"
                        ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
                        : resolve(null)
                }
                ) :
                reject(new Error("Permission API is not supported"))
        )
    }

    setLocation=(latitude:number, longitude:number)=>{
        this.setState({ latitude, longitude },()=>  this.getCurrentCityName(this.state.latitude, this.state.longitude));
    }
    

    getCurrentLocation2=()=>{
        if(this.state.latitude){
            this.getCurrentCityName(this.state.latitude,this.state.longitude)
        }else{
            this.coords().then((coords: any) => {
                const { latitude, longitude } = coords
                localStorage.setItem("latitude", JSON.stringify(latitude));
                localStorage.setItem("longitude", JSON.stringify(longitude));
                this.setLocation(latitude,longitude);
            });   

        }
    }
    
    getCurrentLocation = async() => {

        const latitude:any = await localStorage.getItem("latitude")
        const longitude:any = await localStorage.getItem("longitude")

        this.setState({latitude:latitude,longitude:longitude},()=>{
            this.getCurrentLocation2()
        })
        
    }

    useMyLocation = () => {
        this.getCurrentLocation()
        this.setState({ isLocationInputTouched: !this.state.isLocationInputTouched })
      
    }


    getCurrentCityName = (latitude: number, longitude: number) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCurrentLocationCityName = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCurrentCityNameEndPoint}?latitude=${latitude} &longitude=${longitude}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;


    }
    getAreaByCity = (cityName: string) => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAreaByCityApiCalledId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAreaByCityEndPoint}?city=${cityName}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleInputChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ cityname: event.target.value });
        localStorage.setItem("cityname", JSON.stringify(event.target.value));
        setStorageData("showCityname", event.target.value)
    }



    async componentDidMount() {
        const longitude = localStorage.getItem("longitude")
        const latitude = localStorage.getItem("latitude")
        if (longitude || latitude) {
            console.log("No call")
        } else {
            this.getCurrentLocation();
        }
        let cityname: any = await JSON.parse(localStorage.getItem("cityname") ?? "");
        this.setState({ cityname: cityname })

    }

    // Customizable Area Start
    // Customizable Area End
}
